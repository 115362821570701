/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React, { useContext, lazy, Suspense } from 'react';
import {
    Routes, Route, useLocation
} from 'react-router-dom';
import AbilityContext from '../components/CASL/AbilityContext';
import {
    ACCESS, CREATE, READ, UPDATE,
    CONFIGURATION, CHANNEL_HIERARCHY, RAW_CUSTOMISATION,
    FORMCUSTOMISATION, REFERENCE_FILE_TYPE, MASTER_DATA,
    PAYEE, USER, COMMISSION, RAW_DATA_BATCH, MY_APPROVAL, REFERENCE_FILE, WORKFLOWS, CALCULATION, TRANSACTIONAL_FILE, TRANSACTIONAL_FILE_TYPE, CALCULATION_BATCH, CLAIM, COMMISSION_TYPE, SCHEME_DOCUMENT, TARGET, PAYEE_DATA, DELEGATION, REJECTION_REASON
} from '../components/CASL/CONSTANTS';

import {
    ROOT, ADD_USER, LIST_USER, USER_DETAILS, EDIT_USER, USERS, ACCESS_DENIED, SPLASHSCREEN,
    LIST_REF_FILE_TYPES, ADD_REF_FILE_TYPE, EDIT_REF_FILE_TYPE, LIST_REF_DATA,
    REF_FILE_TYPE_DETAILS, FORM_CUSTOMISATION, REF_FILE_DETAILS, UPLOAD_REF_FILE,
    LOGIN_PAGE, LIST_PAYEE, PAYEE_DETAILS, PAYEE_BULK_DETAILS, ADD_PAYEE, EDIT_PAYEE,
    LIST_RAW_DATA_TABLES, LIST_RAW_DATA_BATCH, LIST_RAW_DATA, RAW_DATA_CUSTOMISATION, ADD_CHANNEL, EDIT_CHANNEL, LIST_CHANNEL, CHANNEL_DETAILS, ADD_SUB_CHANNEL, EDIT_SUB_CHANNEL, SUB_CHANNEL_DETAILS, APPROVALS, APPROVALS_DETAILS, ADD_BULK_PAYEE, EDIT_BULK_PAYEE, LIST_WORKFLOW, CALCULATION_DETAILS, LIST_PAYMENT, LIST_TRANS_FILE,
    LIST_TRANS_FILE_TYPES, ADD_TRANS_FILE_TYPE, TRANS_FILE_TYPE_DETAILS, EDIT_TRANS_FILE_TYPE,
    TRANS_FILE_DETAILS, BULK_TRANS_FILE_DETAILS, UPLOAD_TRANS_FILE, BULK_UPLOAD_TRANS_FILE, EDIT_BULK_UPLOAD_TRANS_FILE, PAYEE_TREE, PAYMENT_REPORT, EDIT_TRANS_FILE, EDIT_REF_FILE, BULK_REF_FILE_DETAILS, BULK_UPLOAD_REF_FILE, EDIT_BULK_UPLOAD_REF_FILE, ADD_CLAIM, EDIT_CLAIM,
    LIST_CLAIMS, CLAIM_DETAILS, REPORTS, LIST_COMMISSION_TYPES, UPLOAD_SCHEME_DOCUMENT, LIST_SCHEME_DOCUMENT, SCHEME_DOCUMENT_DETAILS, LIST_TARGET, ADD_BATCH_TARGET, BATCH_TARGET_DETAILS, EDIT_BATCH_TARGET, LIST_PAYEE_TARGET, PAYEE_TARGET_DETAILS, DELEGATIONS, DELEGATION_DETAILS, LIST_REJECTION_REASON
} from './CONSTANTS';
import Loader from '../components/Loader';

const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Home = lazy(() => import('../pages/Home'));
const AccessDenied = lazy(() => import('../pages/AccessDenied'));
const AddUser = lazy(() => import('../pages/AddUser'));
const ListUser = lazy(() => import('../pages/ListUser'));
const UserDetails = lazy(() => import('../pages/UserDetails'));
const EditUser = lazy(() => import('../pages/EditUser'));
const Users = lazy(() => import('../pages/Users'));
const ListRefFileType = lazy(() => import('../pages/refdata/ListRefFileType'));
const AddRefFiletype = lazy(() => import('../pages/refdata/AddRefFiletype'));
const EditRefFiletype = lazy(() => import('../pages/refdata/EditRefFiletype'));
const ListRefFileData = lazy(() => import('../pages/refdata/ListRefFileData'));
const ListDelegation = lazy(() => import('../pages/Delegation/ListDelegation'));
const DelegationDetails = lazy(() => import('../pages/Delegation/DelegationDetails'));
const FormCustomization = lazy(() => import('../pages/FormCustomization'));
const ListRawDataTables = lazy(() => import('../pages/RawData/ListRawDataTables'));
const AddPayee = lazy(() => import('../pages/Payee/AddPayee'));
const EditPayee = lazy(() => import('../pages/Payee/EditPayee'));
const ListRawDataBatch = lazy(() => import('../pages/RawData/ListRawDataBatch'));
const ListRawData = lazy(() => import('../pages/RawData/ListRawData'));
const RawDataCustomisation = lazy(() => import('../pages/RawData/RawDataCustomisation'));
const AddChannel = lazy(() => import('../pages/Channel/AddChannel'));
const EditChannel = lazy(() => import('../pages/Channel/EditChannel'));
const ListChannel = lazy(() => import('../pages/Channel/ListChannel'));
const AddSubChannel = lazy(() => import('../pages/Channel/AddSubChannel'));
const EditSubChannel = lazy(() => import('../pages/Channel/EditSubChannel'));
const ChannelDetails = lazy(() => import('../pages/Channel/ChannelDetails'));
const SplashScreen = lazy(() => import('../pages/SplashScreen'));
const RefFileTypeDetails = lazy(() => import('../pages/refdata/RefFileTypeDetails'));
const RefFileDetails = lazy(() => import('../pages/refdata/RefFileDetails'));
const UploadRefFile = lazy(() => import('../pages/refdata/UploadRefFile'));
const ListPayee = lazy(() => import('../pages/Payee/ListPayee'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const PayeeDetails = lazy(() => import('../pages/Payee/PayeeDetails'));
const SubChannelDetails = lazy(() => import('../pages/Channel/SubChannelDetails'));
const Approvals = lazy(() => import('../pages/Approval/ListApproval'));
const ApprovalDetails = lazy(() => import('../pages/Approval/ApprovalDetails'));
const BulkTransFileDetails = lazy(() => import('../pages/TransactionalData/BulkTransFileDetails'));
const AddBulkPayee = lazy(() => import('../pages/Payee/AddBulkPayee'));
const EditBulkPayee = lazy(() => import('../pages/Payee/EditBulkPayee'));
const ListWorkflow = lazy(() => import('../pages/Workflow/ListWorkflow'));
const CalculationDetails = lazy(() => import('../pages/Calculation/CalculationDetails'));
const ListCalculation = lazy(() => import('../pages/Calculation/ListCalculation'));
const ListTransFileType = lazy(() => import('../pages/TransactionalData/ListTransFileType'));
const AddTransFiletype = lazy(() => import('../pages/TransactionalData/AddTransFiletype'));
const TransFileTypeDetails = lazy(() => import('../pages/TransactionalData/TransFileTypeDetails'));
const EditTransFiletype = lazy(() => import('../pages/TransactionalData/EditTransFiletype'));
const ListTransactionalFile = lazy(() => import('../pages/TransactionalData/ListTransFile'));
const TransFileDetails = lazy(() => import('../pages/TransactionalData/TransFileDetails'));
const UploadTransFile = lazy(() => import('../pages/TransactionalData/UploadTransFile'));
const BulkUploadTransFile = lazy(() => import('../pages/TransactionalData/BulkUploadTransFile'));
const BulkUploadRefFile = lazy(() => import('../pages/refdata/BulkUploadRefFile'));
const PayeeTree = lazy(() => import('../pages/Payee/PayeeTree'));
const ListPayeePayment = lazy(() => import('../pages/Payee/ListPayeePayment'));
const BulkPayeeDetails = lazy(() => import('../pages/Payee/BulkPayeeDetails'));
const NotFound = lazy(() => import('../pages/NotFound'));
const BulkRefFileDetails = lazy(() => import('../pages/refdata/BulkRefFileDetails'));
const AddEditClaim = lazy(() => import('../pages/Claims/AddEditClaim'));
const ListClaims = lazy(() => import('../pages/Claims/ListClaims'));
const ClaimDetails = lazy(() => import('../pages/Claims/ClaimDetails'));
const Reports = lazy(() => import('../pages/Reports'));
const ListCommissionTypes = lazy(() => import('../pages/Claims/ListCommissionTypes'));
const ListRejectionReasons = lazy(() => import('../pages/Claims/ListRejectionReasons'));
const UploadSchemeDocument = lazy(() => import('../pages/PayeeTarget/UploadSchemeDocument'));
const ListSchemeDocument = lazy(() => import('../pages/PayeeTarget/ListSchemeDocument'));
const SchemeDocumentDetails = lazy(() => import('../pages/PayeeTarget/SchemeDocumentDetails'));
const ListTarget = lazy(() => import('../pages/PayeeTarget/ListTarget'));
const AddEditBatchTarget = lazy(() => import('../pages/PayeeTarget/AddEditBatchTarget'));
const BatchTargetDetails = lazy(() => import('../pages/PayeeTarget/BatchTargetDetails'));
const PayeeTargetDetails = lazy(() => import('../pages/PayeeTarget/PayeeTargetDetails'));
const ListPayeeTarget = lazy(() => import('../pages/PayeeTarget/ListPayeeTarget'));

export const RouterConfig = () => {
    const location = useLocation();
    const ability = useContext(AbilityContext);

    if (location.pathname === '/') {
        document.body.style.backgroundColor = '#fff';
    } else {
        document.body.style.backgroundColor = '#ebebeb';
    }

    return (
        <div>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {/* List all public routes here */}
                    <Route exact path={ROOT} element={<PrivateRoute hasAccess><Home /></PrivateRoute>} />

                    {/* User */}
                    <Route exact path={ADD_USER} element={<PrivateRoute hasAccess={ability.can(CREATE, USER)}><AddUser /></PrivateRoute>} />
                    <Route exact path={LIST_USER} element={<PrivateRoute hasAccess={ability.can(ACCESS, USER)}><ListUser /></PrivateRoute>} />
                    <Route exact path={USER_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, USER)}><UserDetails /></PrivateRoute>} />
                    <Route exact path={EDIT_USER} element={<PrivateRoute hasAccess={ability.can(UPDATE, USER)}><EditUser /></PrivateRoute>} />
                    <Route exact path={USERS} element={<PrivateRoute hasAccess={ability.can(ACCESS, USER)}><Users /></PrivateRoute>} />

                    {/* RefFileType */}
                    <Route exact path={LIST_REF_FILE_TYPES} element={<PrivateRoute hasAccess={ability.can(ACCESS, REFERENCE_FILE_TYPE)}><ListRefFileType /></PrivateRoute>} />
                    <Route exact path={ADD_REF_FILE_TYPE} element={<PrivateRoute hasAccess={ability.can(CREATE, REFERENCE_FILE_TYPE)}><AddRefFiletype /></PrivateRoute>} />
                    <Route exact path={EDIT_REF_FILE_TYPE} element={<PrivateRoute hasAccess={ability.can(UPDATE, REFERENCE_FILE_TYPE)}><EditRefFiletype /></PrivateRoute>} />
                    <Route exact path={REF_FILE_TYPE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, REFERENCE_FILE_TYPE)}><RefFileTypeDetails /></PrivateRoute>} />

                    {/* Reffile */}
                    <Route exact path={LIST_REF_DATA} element={<PrivateRoute hasAccess={ability.can(ACCESS, REFERENCE_FILE)}><ListRefFileData /></PrivateRoute>} />
                    <Route exact path={DELEGATIONS} element={<PrivateRoute hasAccess={ability.can(ACCESS, DELEGATION)}><ListDelegation /></PrivateRoute>} />
                    <Route exact path={DELEGATION_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, DELEGATION)}><DelegationDetails /></PrivateRoute>} />
                    <Route exact path={REF_FILE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, REFERENCE_FILE)}><RefFileDetails /></PrivateRoute>} />
                    <Route exact path={UPLOAD_REF_FILE} element={<PrivateRoute hasAccess={ability.can(CREATE, REFERENCE_FILE)}><UploadRefFile /></PrivateRoute>} />
                    <Route exact path={EDIT_REF_FILE} element={<PrivateRoute hasAccess={ability.can(UPDATE, REFERENCE_FILE)}><UploadRefFile /></PrivateRoute>} />
                    <Route exact path={BULK_REF_FILE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, REFERENCE_FILE)}><BulkRefFileDetails /></PrivateRoute>} />
                    <Route exact path={BULK_UPLOAD_REF_FILE} element={<PrivateRoute hasAccess={ability.can(CREATE, REFERENCE_FILE)}><BulkUploadRefFile /></PrivateRoute>} />
                    <Route exact path={EDIT_BULK_UPLOAD_REF_FILE} element={<PrivateRoute hasAccess={ability.can(CREATE, REFERENCE_FILE)}><BulkUploadRefFile /></PrivateRoute>} />
                    <Route exact path={FORM_CUSTOMISATION} element={<PrivateRoute hasAccess={ability.can(ACCESS, FORMCUSTOMISATION)}><FormCustomization /></PrivateRoute>} />

                    {/* Payee */}
                    <Route exact path={LIST_PAYEE} element={<PrivateRoute hasAccess={ability.can(ACCESS, PAYEE)}><ListPayee /></PrivateRoute>} />
                    <Route exact path={PAYEE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, PAYEE)}><PayeeDetails /></PrivateRoute>} />
                    <Route exact path={ADD_PAYEE} element={<PrivateRoute hasAccess={ability.can(CREATE, PAYEE)}><AddPayee /></PrivateRoute>} />
                    <Route exact path={EDIT_PAYEE} element={<PrivateRoute hasAccess={ability.can(UPDATE, PAYEE)}><EditPayee /></PrivateRoute>} />
                    <Route exact path={PAYEE_BULK_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, PAYEE)}><BulkPayeeDetails /></PrivateRoute>} />
                    <Route exact path={EDIT_BULK_PAYEE} element={<PrivateRoute hasAccess={ability.can(UPDATE, PAYEE)}><EditBulkPayee /></PrivateRoute>} />
                    <Route exact path={PAYEE_TREE} element={<PrivateRoute hasAccess={ability.can(ACCESS, PAYEE)}><PayeeTree /></PrivateRoute>} />
                    <Route exact path={PAYMENT_REPORT} element={<PrivateRoute hasAccess={ability.can(ACCESS, PAYEE_DATA)}><ListPayeePayment /></PrivateRoute>} />

                    {/* RawData */}
                    <Route exact path={LIST_RAW_DATA_TABLES} element={<PrivateRoute hasAccess={ability.can(ACCESS, RAW_CUSTOMISATION)}><ListRawDataTables /></PrivateRoute>} />
                    <Route exact path={LIST_RAW_DATA_BATCH} element={<PrivateRoute hasAccess={ability.can(ACCESS, RAW_DATA_BATCH)}><ListRawDataBatch /></PrivateRoute>} />
                    <Route exact path={LIST_RAW_DATA} element={<PrivateRoute hasAccess={ability.can(ACCESS, RAW_DATA_BATCH)}><ListRawData /></PrivateRoute>} />
                    <Route exact path={RAW_DATA_CUSTOMISATION} element={<PrivateRoute hasAccess={ability.can(ACCESS, RAW_CUSTOMISATION)}><RawDataCustomisation /></PrivateRoute>} />

                    {/* Channel & Subchannel */}
                    <Route exact path={ADD_CHANNEL} element={<PrivateRoute hasAccess={ability.can(CREATE, CHANNEL_HIERARCHY)}><AddChannel /></PrivateRoute>} />
                    <Route exact path={EDIT_CHANNEL} element={<PrivateRoute hasAccess={ability.can(UPDATE, CHANNEL_HIERARCHY)}><EditChannel /></PrivateRoute>} />
                    <Route exact path={LIST_CHANNEL} element={<PrivateRoute hasAccess={ability.can(ACCESS, CHANNEL_HIERARCHY)}><ListChannel /></PrivateRoute>} />
                    <Route exact path={CHANNEL_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, CHANNEL_HIERARCHY)}><ChannelDetails /></PrivateRoute>} />
                    <Route exact path={ADD_SUB_CHANNEL} element={<PrivateRoute hasAccess={ability.can(CREATE, CHANNEL_HIERARCHY)}><AddSubChannel /></PrivateRoute>} />
                    <Route exact path={EDIT_SUB_CHANNEL} element={<PrivateRoute hasAccess={ability.can(UPDATE, CHANNEL_HIERARCHY)}><EditSubChannel /></PrivateRoute>} />
                    <Route exact path={SUB_CHANNEL_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, CHANNEL_HIERARCHY)}><SubChannelDetails /></PrivateRoute>} />

                    {/* Approvals */}
                    <Route exact path={APPROVALS} element={<PrivateRoute hasAccess={ability.can(ACCESS, MY_APPROVAL)}><Approvals /></PrivateRoute>} />
                    <Route exact path={APPROVALS_DETAILS} element={<PrivateRoute hasAccess={ability.can(ACCESS, MY_APPROVAL)}><ApprovalDetails /></PrivateRoute>} />
                    <Route exact path={ADD_BULK_PAYEE} element={<PrivateRoute hasAccess={ability.can(CREATE, PAYEE)}><AddBulkPayee /></PrivateRoute>} />

                    {/* Workflow */}
                    <Route exact path={LIST_WORKFLOW} element={<PrivateRoute hasAccess={ability.can(ACCESS, WORKFLOWS)}><ListWorkflow /></PrivateRoute>} />

                    {/* Calculations */}
                    <Route exact path={CALCULATION_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, CALCULATION_BATCH)}><CalculationDetails /></PrivateRoute>} />
                    <Route exact path={LIST_PAYMENT} element={<PrivateRoute hasAccess={ability.can(ACCESS, CALCULATION_BATCH)}><ListCalculation /></PrivateRoute>} />

                    {/* Transaction file type */}
                    <Route exact path={LIST_TRANS_FILE_TYPES} element={<PrivateRoute hasAccess={ability.can(ACCESS, TRANSACTIONAL_FILE_TYPE)}><ListTransFileType /></PrivateRoute>} />
                    <Route exact path={ADD_TRANS_FILE_TYPE} element={<PrivateRoute hasAccess={ability.can(CREATE, TRANSACTIONAL_FILE_TYPE)}><AddTransFiletype /></PrivateRoute>} />
                    <Route exact path={TRANS_FILE_TYPE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, TRANSACTIONAL_FILE_TYPE)}><TransFileTypeDetails /></PrivateRoute>} />
                    <Route exact path={EDIT_TRANS_FILE_TYPE} element={<PrivateRoute hasAccess={ability.can(UPDATE, TRANSACTIONAL_FILE_TYPE)}><EditTransFiletype /></PrivateRoute>} />

                    {/* Trans file */}
                    <Route exact path={LIST_TRANS_FILE} element={<PrivateRoute hasAccess={ability.can(ACCESS, TRANSACTIONAL_FILE)}><ListTransactionalFile /></PrivateRoute>} />
                    <Route exact path={TRANS_FILE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, TRANSACTIONAL_FILE)}><TransFileDetails /></PrivateRoute>} />
                    <Route exact path={UPLOAD_TRANS_FILE} element={<PrivateRoute hasAccess={ability.can(CREATE, TRANSACTIONAL_FILE)}><UploadTransFile /></PrivateRoute>} />
                    <Route exact path={EDIT_TRANS_FILE} element={<PrivateRoute hasAccess={ability.can(UPDATE, TRANSACTIONAL_FILE)}><UploadTransFile /></PrivateRoute>} />
                    <Route exact path={BULK_TRANS_FILE_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, TRANSACTIONAL_FILE)}><BulkTransFileDetails /></PrivateRoute>} />
                    <Route exact path={BULK_UPLOAD_TRANS_FILE} element={<PrivateRoute hasAccess={ability.can(CREATE, TRANSACTIONAL_FILE)}><BulkUploadTransFile /></PrivateRoute>} />
                    <Route exact path={EDIT_BULK_UPLOAD_TRANS_FILE} element={<PrivateRoute hasAccess={ability.can(CREATE, TRANSACTIONAL_FILE)}><BulkUploadTransFile /></PrivateRoute>} />

                    {/* Reports */}
                    <Route exact path={REPORTS} element={<PrivateRoute hasAccess={ability.can(ACCESS, COMMISSION)}><Reports /></PrivateRoute>} />

                    <Route exact path={SPLASHSCREEN} element={<SplashScreen />} />
                    <Route exact path={LOGIN_PAGE} element={<LoginPage />} />
                    <Route exact path={ACCESS_DENIED} element={<AccessDenied />} />

                    {/* claims */}
                    <Route exact path={ADD_CLAIM} element={<PrivateRoute hasAccess={ability.can(CREATE, CLAIM)}><AddEditClaim /></PrivateRoute>} />
                    <Route exact path={EDIT_CLAIM} element={<PrivateRoute hasAccess={ability.can(UPDATE, CLAIM)}><AddEditClaim /></PrivateRoute>} />
                    <Route exact path={LIST_CLAIMS} element={<PrivateRoute hasAccess={ability.can(ACCESS, CLAIM)}><ListClaims /></PrivateRoute>} />
                    <Route exact path={CLAIM_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, CLAIM)}><ClaimDetails /></PrivateRoute>} />

                    {/* Commission types */}
                    <Route exact path={LIST_COMMISSION_TYPES} element={<PrivateRoute hasAccess={ability.can(ACCESS, COMMISSION_TYPE)}><ListCommissionTypes /></PrivateRoute>} />
                    <Route exact path={LIST_REJECTION_REASON} element={<PrivateRoute hasAccess={ability.can(ACCESS, REJECTION_REASON)}><ListRejectionReasons /></PrivateRoute>} />

                    {/* Scheme document */}
                    <Route exact path={UPLOAD_SCHEME_DOCUMENT} element={<PrivateRoute hasAccess={ability.can(CREATE, SCHEME_DOCUMENT)}><UploadSchemeDocument /></PrivateRoute>} />
                    <Route exact path={LIST_SCHEME_DOCUMENT} element={<PrivateRoute hasAccess={ability.can(ACCESS, SCHEME_DOCUMENT)}><ListSchemeDocument /></PrivateRoute>} />
                    <Route exact path={SCHEME_DOCUMENT_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, SCHEME_DOCUMENT)}><SchemeDocumentDetails /></PrivateRoute>} />
                    <Route exact path={LIST_TARGET} element={<PrivateRoute hasAccess={ability.can(ACCESS, TARGET)}><ListTarget /></PrivateRoute>} />
                    <Route exact path={ADD_BATCH_TARGET} element={<PrivateRoute hasAccess={ability.can(CREATE, TARGET)}><AddEditBatchTarget /></PrivateRoute>} />
                    <Route exact path={BATCH_TARGET_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, TARGET)}><BatchTargetDetails /></PrivateRoute>} />
                    <Route exact path={PAYEE_TARGET_DETAILS} element={<PrivateRoute hasAccess={ability.can(READ, TARGET)}><PayeeTargetDetails /></PrivateRoute>} />
                    <Route exact path={EDIT_BATCH_TARGET} element={<PrivateRoute hasAccess={ability.can(UPDATE, TARGET)}><AddEditBatchTarget /></PrivateRoute>} />
                    <Route exact path={LIST_PAYEE_TARGET} element={<PrivateRoute hasAccess={ability.can(ACCESS, TARGET)}><ListPayeeTarget /></PrivateRoute>} />
                    {/* List a generic 404-Not Found route here */}
                    <Route exact path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </div>
    );
};